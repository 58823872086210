import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Swal from "sweetalert2";
import Scripts from "./Script";
import { Button, Modal, Row, Col } from "react-bootstrap";
import storage from "../../../../Storage";
import RangeCredit from "../../../Components/Game/Addons";
import Engine from "../Engine";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";
import {setWinnerText, setMeToGame} from "../../../../actions/crashGame";
import {Event, __, isValidNumber, forceSatoshiFormat, User, wait, Game, sendNotfication} from "../../../../Helper";
import Menus from "./Menus";
import AutoBet from "./AutoBet";

class ManualBet extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonText: 'Place Bet',
            buttonType: 'btn-bet',
            barType: 'next-round',
            inputDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            showNext: true,
            payout: forceSatoshiFormat(storage.getKey('odd') ? storage.getKey('odd') : 3.00),
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : 50.00),
            // amount: '10.00',
            token: storage.getKey('token') ? storage.getKey('token') : null,
            hotkey:  storage.getKey('hotkey') ? storage.getKey('hotkey') : "OFF",
            auto : false,
            online_users: 0,
            bid:'',
            our_bid:''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.setBet = this.setBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(){
        if(this._isMounted){
            const engine = Engine

            this.setState({ engine: engine })

            //Load Coin From Redux
            this.props.gameCoin();

            window.addEventListener('keypress', this.handleHotKey.bind(this));
            document.addEventListener('mousedown', this.handleClickOutside);

            // Game Event
            engine.trigger.on("game_status", (data) => this.checkStatus(data));

            engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
            engine.trigger.on("busted_crash", () => this.checkBustedGame());
            engine.trigger.on("started_crash", () => this.checkStartedGame());

            // User Event
            engine.trigger.on("play_crash", data => this.handlePlay(data));
            engine.trigger.on("finish_crash", data => this.handleFinish(data));
            // engine.trigger.on("finish_1_crash", data => this.handleFinish2(data));
            //Error
            engine.trigger.on("error_crash", data => this.handleError(data));

            // Stop manual bet
            engine.trigger.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            engine.trigger.on("auto_bet", data => this.handleAuto(data));
        }
    }

    componentWillMount() {
      
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('keypress', this.handleHotKey.bind(this));
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

            let value = parseFloat(this.state.payout);

            if(value < 1.01)
                value = 3.00;
            
            if(isNaN(value))
                value = 3.00;
                // sendNotfication("Please Input Minimum Cahout Value of 1.01", 'info', 'top-center');

            if(value > 1000000)
                value = 1000000;

            this.setState({ payout: parseFloat(value).toFixed(2) });

            // console.log(value);

            var amount = this.wrapperRef.current.value;

            if (amount === '') {
                amount = 50.00;
                this.setState({ amount: 50.00 });
            }

            if(parseFloat(amount) < 10.00){
                amount = 50.00;
                this.setState({ amount: forceSatoshiFormat(50.00) });
            }

            if(parseFloat(amount) > 3000.00){
                amount = 3000.00;
                this.setState({ amount: forceSatoshiFormat(3000.00) });
            }

            if(parseFloat(amount) <= 0){
                amount = 50.00;
                this.setState({ amount: forceSatoshiFormat(50.00) });
            }
            else {
                if(amount === "NaN") amount = 50.00;
                this.setState({ amount: forceSatoshiFormat(amount) });
            }
        }
    }

    handleInputChange(event){
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if(name === 'amount'){
            if(!isValidNumber(value)) return;
        }

        if(name === 'payout'){
            storage.setKey('odd', value);
            if(value >= 999999){
                return;
            }
            else if(value  === ''){
                sendNotfication("Please Input Minimum Cahout Value of 1.01", 'info', 'top-center');
            }
        }

        if(name === 'amount'){
            storage.setKey('lam', value);
        }

        this.setState({ [name]: value });
    }
    
    stopManual() {
        this.setState({ started: false, buttonText: 'BET', inputDisabled: false });
    }

    checkStatus(data)
    {
        if(this._isMounted){
            switch (data.status)
            {
                case 'waiting':
                    this.checkWaitingGame();
                    break;
                case 'started':
                    this.checkStartedGame();
                    break;
                case 'busted':
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleAuto = (data) => {
        if(this._isMounted){

            // let { amount, payout } = data;
            // console.log(data);

            let { amount, payout } = this.state;
            console.log(amount + " - "+payout);

            if(!payout) return alert('payout is empty.');
            if(!amount) return alert('amount is empty.');

            this.setState({ amount: amount, payout: payout, auto: true});
            
            this.setState({
                added: false,
                inputDisabled: true,
                buttonType: 'btn-bet-success-crash text-dark btn-p no-shadow',
                buttonText: "Automatic"
            });

            if(this.state.gameStatus === 'started'){
                this.holdBet();
            }
            else if(this.state.gameStatus === 'waiting'){
                this.setBet();
            }
        }
    };

    handleError(data) {
        if(this._isMounted){
            this.props.setMeToGame(false);
            
            this.setState({ holding: false, added: false, inputDisabled: false });
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if(!__.isUndefined(data.code)){
                if(data.code === 'credit')
                    this.props.setWallet(true, data.uid);
            }

            this.setState({ clicked: false })
            sendNotfication(data.message, 'info', 'top-center');
        }
    }

    setDefaultButton = () => {
        if(this._isMounted){
            clearInterval(this.state.buttonProgress);
            this.setState({
                added: false,
                holding: false,
                inputDisabled: false,
                buttonType: 'btn-bet',
                // buttonText: "Place Bet "+ this.state.payout + " Kshs"
                buttonText: "Place Bet",
                barType: "next-round",
                showNext:true
           });
        }
    };

    setWaitingButton = () => {
        if(this._isMounted){
            this.setState({
                added: true,
                inputDisabled: true,
                buttonType: 'btn-bet-success-waiting text-dark btn-p shadow',
                buttonText: "Please Wait...",
                barType: "next-round",
                showNext:true
            });
        }
    };

    setOutButton = () => {
        if(this._isMounted){
            this.setState({
                inputDisabled: false,
                buttonType: 'btn-bet-success',
                buttonText: "CANCEL (Next Round)",
                barType: "next-round",
                showNext:true,
                im_in_game: false
            });
        }
    };

  

    setBet(){
        if(this._isMounted){
            this.setState({ clicked: true,  im_in_game: true });
            let { engine, amount, payout, token } = this.state;
            let { bid } = this.props;
            
            engine.coin   = 'kshs';
            engine.token  = token;
            engine.amount = amount;
            engine.payout = parseInt(payout * 100) * parseInt(amount);
            engine.odd = payout;
            engine.bid = bid;
            engine.play();
        }
    }

    cashOut(){
        if(this._isMounted){
            let { engine } = this.state;
            this.setState({ im_in_game: false});
            engine.finish(Game['current_amount']);
        }
    }

    handlePlay(data){
        //  console.log("handle play " + JSON.stringify(data) );
        if(this._isMounted){
            if(data.token === this.state.token){
                this.setState({ im_in_game: true});
                this.props.setMeToGame(true);
            }
        }
    }

    handleFinish(data){

    //    console.log("data token:" + data.token + " state token:"+ this.state.token + " token2:"+data.token2);
    //    console.log('gid ' + this.state.bid);
    
        if(this._isMounted){
           if(data.token === this.state.token){
            clearInterval(this.state.buttonProgress);
            this.props.setWinnerText("   You Cashed Out at " + data.current / 100);
            this.props.setMeToGame(false);
            this.setDefaultButton();
            this.setState({ im_in_game: false});
           }
       }
    }

    checkWaitingGame(){
        if(this._isMounted){
            this.props.setWinnerText('');

            this.setState({ gameStatus: 'waiting'});

            let { im_in_game } = this.state;
            let { engine, online_users } = this.state;

            let today = new Date()
            let curHr = today.getHours()

            if (curHr < 10) {
                online_users = this.getRandomInt(100,120);
            }
            else if (curHr < 12) {
                online_users = this.getRandomInt(130,150);
            } else if (curHr < 18) {
                online_users = this.getRandomInt(290,310);
            } else if (curHr < 20) {
                online_users = this.getRandomInt(315,350);
            }
             else {
                online_users = this.getRandomInt(355,400);
            }
            this.setState({ online_users: online_users });

            if(this.state.holding)
            {
                this.setState({ holding: false});
                this.placeBet();
            }
        }
    }

    checkStartedGame(){
        if(this._isMounted){
            
            this.setState({ gameStatus: 'started', online_users: online_users  });
            let { im_in_game } = this.state;
            let { engine,online_users } = this.state;

            let coin = 'kshs';
            let amount = engine.amount

            let today = new Date()
            let curHr = today.getHours()

            if (curHr < 10) {
                online_users = this.getRandomInt(100,120);
            }
            else if (curHr < 12) {
                online_users = this.getRandomInt(130,150);
            } else if (curHr < 18) {
                online_users = this.getRandomInt(290,310);
            } else if (curHr < 20) {
                online_users = this.getRandomInt(315,350);
            }
             else {
                online_users = this.getRandomInt(355,400);
            }
        //    console.log("game",this.props.bid);
        let game_1_status = localStorage.getItem('game_1');
        let game_2_status = localStorage.getItem('game_2');

            this.setState({ gameStatus: 'started', online_users: online_users  });

            if(this.state.bid == 0 && this.state.bid != 1 ){
            if((im_in_game === true && game_2_status === false) || this.state.clicked)
            {
                this.setState({ inputDisabled: false, buttonType: "btn-bet-success-crash text-dark",barType: "next-round-success", clicked: false });
              

                let counter = 0;
                let self = this;
                this.state.buttonProgress = setInterval(function() {
                        let calc = amount * (Game['current_amount'] - 1);
                        // self.setState({ buttonText: 'CashOut ' + forceSatoshiFormat(calc) + ' ' + coin });
                        self.setState({ buttonText: 'CashOut ' + forceSatoshiFormat(calc) + ' KES',showNext: false,buttonType: "btn-bet-success-progress",});
                        counter++;

                        console.log("current amount" +Game['current_amount'] + " | payout"+ this.state.payout);
                        //Helper
                        
                        if(parseFloat(Game['current_amount']) === parseFloat(this.state.payout)){
                            clearInterval(this.state.buttonProgress);
                            this.cashOut();
                        }
                    }
                        .bind(this),
                    50
                );
            }
        }
        }
    }

  

    checkBustedGame(){
        if(this._isMounted){
            this.setState({ gameStatus: 'busted', clicked: false });

            // let { im_in_game } = this.props;
            let { im_in_game } = this.state;

            if(!this.state.holding)
            {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if(im_in_game)
            {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    isCorrectBet(){
        let { amount, payout } = this.state;

        if(!isValidNumber(amount))
            return false;

        if((payout * 100) < 100)
            return false;

        if((payout * 100) === 100)
            return false;

        return true;
    }

    placeBet(){
        if(this._isMounted){
            let { engine } = this.state;

            engine.isHolding = false;
            this.setWaitingButton();
            this.setBet();
            // if(!this.state.auto){
            //     this.setBet();
            // }
          
        }
    }

    holdBet(){
        if(this._isMounted){
            let { engine } = this.state;
            engine.isHolding = true;
            this.setState({ holding: true,im_in_game: true });
            this.setOutButton();
        }
    }

    handleBet(e){
       
        if(this._isMounted){
            e.preventDefault();

            // this.setState({ token: storage.getKey('token') });
            let { amount, payout, holding, gameStatus, token } =  this.state;
            // let { im_in_game,isLogged,bid} = this.props;

            let {isLogged,bid} = this.props;
           // Check User
            if(!isLogged){
               return Event.emit('showAuthModal', true);
            }

            console.log('test bid ' +bid);
            localStorage.setItem("game_1", true);
            localStorage.setItem("game_2", false);

            // Check is Correct Bet
            if(!this.isCorrectBet())
                return false;

            // this.setState({ payout: (payout * 1).toFixed(2) });
            this.setState({ payout: (payout * 1).toFixed(2), auto: false, bid: bid, im_in_game: true });

            let {im_in_game} = this.state;
            // Check Game Status to Play
            // eslint-disable-next-line default-case
           
                switch (gameStatus)
                {
                    case 'waiting':
                        this.placeBet();
                    break;
    
                    case 'busted':
                        if(holding){
                            this.setDefaultButton();
                        }
                        else this.holdBet();
                    break;
    
                    case 'started':
    
                        if(im_in_game)
                            this.cashOut();
    
                        else if(holding)
                            this.setDefaultButton();
    
                        else
                            this.holdBet();
                    break;
                }
            

        }
    }



    hotkeyChange(){
        if(this._isMounted){
            if(this.state.hotkey === "OFF"){
                User['denied_hotkey'] = false;
                storage.setKey("hotkey", 'ON');
                this.setState({ hotkey: "ON"})
            }
            else {
                storage.setKey("hotkey", 'OFF');
                this.setState({ hotkey: "OFF"})
            }
        }
    }

    handleHotKey(e){
        if(this._isMounted){
            if(User['denied_hotkey'])
            {
                User['denied_hotkey'] = false;
                storage.setKey('hotkey', "OFF");
                this.setState({ hotkey: 'OFF'})
            }

            if(storage.getKey('hotkey') === "ON")
            {
                var char = e.which || e.keyCode;
                if(char === 98){
                   this.handleBet(e);
                }
                if(char === 102){
                    var calc = parseFloat(this.state.payout * 2);
                    var max = Math.max(1.00, calc);
                    this.setState({ payout: max.toFixed(2)})
                }
                if(char === 101){
                    var calc = parseFloat(this.state.payout / 2);
                    var max = Math.max(1.00, calc);
                    this.setState({ payout: max.toFixed(2)})
                }

                this.handleInputChange(e);
            }
        }
    }

    setMax = (e) => {
        e.preventDefault();
        // let max = this.props.credit;
        var max = 200
        if(max === null) return;

        this.setState({ amount: forceSatoshiFormat(max) });
        storage.setKey('lam', forceSatoshiFormat(max));
    };

    setMin = (e) => {
        e.preventDefault();
        this.setState({ amount: '20.00' });
        storage.setKey('lam', '20.00');
    };

    multi = (e) => {
        // var max = this.state.amount * 2
        var max = 50
        this.setState({ amount: forceSatoshiFormat(max) });
        storage.setKey('lam', forceSatoshiFormat(max));
    }

    devide = (e) => {
        // var max = this.state.amount / 2
        var max = 100
        max = Math.max(max, 0.00000010)
        this.setState({ amount: forceSatoshiFormat(max) });
        storage.setKey('lam', forceSatoshiFormat(max));
    }
    
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    render(){
        let { amount, inputDisabled, payout, buttonType,barType, buttonText, hotkey,showNext } = this.state;
        let { mobile, coin,isLogged } = this.props;
        let hotKeyColor = (hotkey === "OFF") ? 'label-grey': 'label-success';

        if(amount === "NaN") amount = 10.00;

        return(
            <div onKeyPress={ (e) => this.handleHotKey(e)}>
                <form className="w-100 tbet-tp" onSubmit={(e) => { this.handleBet(e) }}>
                    <Row>
                                           
                        <Col xl={12} md={12} sm={12}>
                            <div className={"form-group mb-1 payout"}>
                                <table class="tables" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <td className="col-md-4 col-sm-4" style={{padding:'0px', width:'25%'}}>
                                                <div className="bet-amount">
                                                    <label className="bet-amount-label">Amount</label>
                                                    <input
                                                        ref={this.wrapperRef}
                                                        disabled={inputDisabled}
                                                        type="text"
                                                        className="form-control text-left bet-amount-input"
                                                        id="amount"
                                                        name="amount"
                                                        placeholder="..."
                                                        value={amount}
                                                        autoComplete={"off"}
                                                        onKeyUp={this.handleInputChange}
                                                        onChange={this.handleInputChange} />
                                                </div>
                                            </td>
                                         
                                           
                                        </tr>
                                        <tr>
                                        <td className="col-md-4 col-sm-4" style={{padding:'0px'}}>
                                                <div className="bet-amount">
                                                    <label className="bet-amount-label">Quick stake</label>
                                                    <RangeCredit 
                                                        multi={this.multi}
                                                        devide={this.devide}
                                                        max={this.setMax}
                                                        min={this.setMin}
                                                    />
                                                </div>
                                            </td> 
                                        </tr>
                                        <tr>
                                        <td className="col-md-4 col-sm-4 mt-3" style={{padding:'0px', width:'100%'}}>
                                                <div className="bet-amount">
                                                <label className="cashout-label">Cashout</label>
                                                    <div className="input-group" style={{border:'none'}}>
                               
                                                        <input
                                                            disabled={inputDisabled}
                                                            type="number"
                                                            className="form-control text-left cashout-input"
                                                            id="payout"
                                                            name="payout"
                                                            min="1.01"
                                                            max="99999"
                                                            step="0.01"
                                                            placeholder="..."
                                                            value={payout}
                                                            autoComplete={"off"}
                                                            // onKeyUp={this.handleInputChange}
                                                            onChange={this.handleInputChange} />
                                                            {/* <div className="input-group-append btns">
                                                            <span onclick="this.parentNode.querySelector('input[type=number]').stepUp()" className="input-group-text" style={{paddingRight:"19px",paddingLeft:"19px"}}>-</span>
                                                            <span className="input-group-text" style={{paddingRight:"19px",paddingLeft:"19px"}}>+</span>
                                                            </div> */}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>    
                            </div>
                        </Col>
                        <Col xl={12} md={12} sm={12}>
                            <div className={ "form-group mb-1 bet-input payout"}>
                                <table class="tables" style={{width:'95%'}}>
                                    <tbody>
                                        <tr>
                                            <td className="col-md-12 col-sm-12" style={{padding:'0px'}}>
                                                <div className="betbtn text-uppercase text-white">Possible Win <span className="ml-2 text-success">KES 
                                                     { forceSatoshiFormat(amount * payout)}
                                                </span></div>
                                            </td>

                                            {/* <td className="col-md-6 col-sm-6" style={{padding:'0px'}}>
                                                <div className="bet-amount">
                                                    <AutoBet isLogged={isLogged}/>
                                                </div>
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </table> 
                                
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl={12} md={12} sm={12} className="m-auto text-center">
                            {/* <h6 className="text-uppercase">Target Profit  <span className="ml-4 text-success">KES 
                            { forceSatoshiFormat(amount * payout - amount)}
                            </span></h6> */}

                            {/* <div className="betbtn text-uppercase text-white">Possible Win <span className="ml-2 text-success">KES 
                                { forceSatoshiFormat(amount * payout)}
                            </span></div> */}

                        </Col>
                        {/* <Col xl={6} md={5} sm={6}>
                            <h6 className="text-success">KES { forceSatoshiFormat(amount * payout - amount)} </h6>
                        </Col> */}
                    </Row>
                    <Row>
                        {/* <Col md={12} sm={12} xs={12} className="m-auto text-center" style={{paddingRight:"0"}}> */}
                        <Col md={9} sm={9} xs={9} className="m-auto text-center pr-0">
                            <div className={ "form-group rev"}>
                                <div className="btn-group btn-block" style={{width:'100%',height:'50px'}}>
                                    <Button variant={'btn btn-block ' + buttonType}
                                        disabled={inputDisabled}
                                        type="submit">
                                        {buttonText}<br/>
                                        {showNext ?
                                           <p className={barType} style={{fontSize:'9px',margin:'0 0 5px 0'}}>on the next round</p>
                                           :
                                           <p className={barType} style={{fontSize:'9px',margin:'0 0 5px 0'}}>on this round</p>
                                        }  
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={3} sm={3} xs={3} className="text-center pl-0">
                            <div className={ "form-group rev mt-0 mb-2"}>
                                 <AutoBet isLogged={isLogged}/>
                            </div>
                        </Col> */}
                    </Row>
                    {/* <Row>
                    <Col md={12} sm={12} className="m-auto hide-mobile">
                            <div className="form-inline">
                                <Col md={12} sm={12} className="font-12 text-grey" style={{textAlign: 'center'}}>
                                    <Menus/>
                                  
                                </Col>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                    <Col md={12} sm={12}>
                            <div className='' style={{textAlign:'center'}}>
                                        <p className="mt-2"> 
                                    
                                        Users Online <span style={{color:'#00ffff',fontWeight:'600'}}>{this.state.online_users}</span></p>
                            </div>
                        </Col>
                    </Row> */}
                </form>
            </div>
        );
    }
}

class HotKey extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse'
        };
    }

    toggleModal = (e) => {
        if(e !== undefined)
            e.preventDefault();
        
        this.setState({ show: !this.state.show, effect: !this.state.show ? 'zoomIn' : 'zoomOut' });
    };

    render(){
        return(
            <>
                <button className={'btn btn-xs stc mt-1 pl-1'} onClick={e => this.toggleModal(e)}>
                    <i className={'cp mdi mdi-information text-info font-15'}/>
                </button>
                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.toggleModal}
                    aria-labelledby="help-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header>
                        Hot Keys
                        <button type="button" className="close p-2" onClick={e => this.toggleModal(e)}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body closeButton className="modal-helper text-center">
                    <Row className="shortcut-helper">
                        <Col sm="12">
                            <span className="badge badge-soft-danger">B</span>
                            <i className="mdi mdi-arrow-right align-middle"></i>
                            <span className="badge badge-soft-secondary"> BET</span>
                        </Col>
                        <Col sm="12" className="my-2">
                            <span className="badge badge-soft-danger"> E</span>
                            <i className="mdi mdi-arrow-right align-middle"></i>
                            <span className="badge badge-soft-secondary"> HALF PAYOUT</span>
                        </Col>
                        <Col sm="12">
                            <span className="badge badge-soft-danger"> F</span>
                            <i className="mdi mdi-arrow-right align-middle"></i>
                            <span className="badge badge-soft-secondary"> DOUBLE PAYOUT</span>
                        </Col>
                    </Row>
                    </Modal.Body>
                </Modal>
            </>    
            );
    }
}

ManualBet.propTypes = {
    coin: PropTypes.string,
    im_in_game: PropTypes.bool,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    // im_in_game: state.items.im_in_game,
    im_in_game: false,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setWinnerText, setMeToGame, setWallet })(ManualBet);