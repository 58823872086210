import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Cookies from 'js-cookie'
import UserHeader from './Parts/Header/Header-User'
import GuestHeader from './Parts/Header/Header-Guest'
import Sidebar from './Parts/Sidebar'
import Footer from './Parts/Footer'
import PrivacyPolicy from './Parts/Sidebar/PrivacyPolicy'
import socket from '../../Socket'
import { Event, decode, encode, wait, isMobile } from '../../Helper'
import WalletAlert from '../Components/User/Wallet/Alert'
import C from '../../Constant'
import Login from './Auth/Login'
import storage from '../../Storage'

import Engine from '../Games/Crash/Engine'
// import Engine from '../Games/Crash/Engine2'
// import Canvas from '../Games/Crash/Graphic'
import Canvas from '../Games/Crash/Spin/index'
import Mobi from '../Games/Crash/Graphic/Mobi'
import Bet from '../Games/Crash/Bet'
import Bet1 from '../Games/Crash/Bet1'
import Queue from '../Games/Crash/Queue'
import Queue2 from '../Games/Crash/Queue2'
import Players from '../Games/Crash/Players'
import Players_spin from '../Games/Crash/Players'
import Menus from '../Games/Crash/includes/Menus'
import Affiliate from '../Pages/Affiliate'
import Carousel from './Parts/Carousel'

import HistoryList from '../Games/Crash/includes/HistoryList'

import ManualBet1 from '../Games/Crash/includes/ManualBet1'
import ManualBet2 from '../Games/Crash/includes/ManualBet2'
import ManualBet3 from '../Games/Crash/includes/ManualBet3'
import ManualBet4 from '../Games/Crash/includes/ManualBet4'

class Index extends React.Component {
  _Mounted = false
  constructor(props) {
    super(props)
    this.state = {
      mobile: false,
      isLogged: false,
      bid: '',
      token: false,
      header: <GuestHeader t={this.props.t} location={this.props.location} />,
      auth: false,
      code: '',
      betAmounts: [0, 0, 0, 0], // To store bet amounts from 4 components
      placedBets: [],
    }
    this.handleResize = this.handleResize.bind(this)
  }
  componentWillMount() {
    wait(500).then(() => {
      this._Mounted = true
    })
  }

  componentDidMount() {
    socket.on(C.ONLINE, (status) => this.loginUser(decode(status)))
    Event.on('showAuthModal', (status) => this.activeAuth(status))

    /**
     * Initialize Authentication
     */
    const sessionCookie = Cookies.get('auth')

    if (storage.getKey('token') !== null && sessionCookie) {
      socket.emit(
        C.ONLINE,
        encode({
          jwt_token: storage.getKey('jwt_token'), //fake
          user_token: storage.getKey('user_token'), //fake
          security_key: storage.getKey('security_key'), //fake
          secret_user: storage.getKey('secret_user'), //fake
          secret_realtime: storage.getKey('secret_realtime'), //fake
          client_system: storage.getKey('client_system'), //fake
          token_key: storage.getKey('token_key'), //fakeengine
          secret_token: storage.getKey('secret_token'), //fake
          token: storage.getKey('token'), // REAL
        }),
      )
    }
    wait(600).then(() => {
      this.handleResize()
    })
    window.addEventListener('resize', this.handleResize)

    if (storage.getKey('token')) {
      // this.setState({ isLogged: true, token: storage.getKey('token') })
      this.setState({ token: storage.getKey('token') })
    }

    wait(500).then(() => {
      Engine.getStatus()
    })
  }

  activeAuth = (status) => {
    this.setState({ auth: status })
  }

  loginUser = (data) => {
    // console.log("check data "+ storage.getKey('token'));
    wait(500).then(() => {
      if (data.status === true) {
        this.setState({
          header: (
            <UserHeader t={this.props.t} location={this.props.location} />
          ),
          isLogged: true,
          code: data.referral_code

        })
        Cookies.set('uid', data.id, { expires: 14 })
        Cookies.set('auth', true, { expires: 14 })
        storage.setKey('name', data.name)
        storage.setKey('email', data.email)
        storage.setKey('credit', data.credit)
        storage.setKey('avatar', data.avatar)
        storage.setKey('friends', data.friends)
        storage.setKey('room', data.room)
        storage.setKey('token', storage.getKey('token'))
        // storage.setKey('referral_code', data.referral_code)
        // this.state.code = data.referral_code;
      } else {
        wait(7000).then(() => {
          localStorage.clear()
        })
      }
    })
  }
  getWidth() {
    return document.documentElement.clientWidth || document.body.clientWidth
  }
  handleResize() {
    if (this.getWidth() < 1540) {
      this.setState({ col: 'col-xl-12' })
      Event.emit('hide_games')
    } else {
      this.setState({ col: 'col-xl-9' })
      Event.emit('show_min_games')
    }

    if (isMobile()) {
      this.setState({ mobile: true })
    } else {
      this.setState({ mobile: false })
    }

    if (isMobile()) {
      this.setState({ padding: 'p-0', ovh: 'ovh' })
    }
  }

  // Function to update bet amounts
  updateBetAmount = (index, amount) => {
    this.setState(prevState => {
      const updatedBetAmounts = [...prevState.betAmounts];
      updatedBetAmounts[index] = parseFloat(amount);
      return { betAmounts: updatedBetAmounts };
    });
  };

  // Calculate total bet amount
  calculateTotalBetAmount = () => {
    return this.state.betAmounts.reduce((total, amount) => total + amount, 0);
  };


  // Function to handle placing a bet
  handlePlaceBet = (index, amount) => {
    this.setState(prevState => {
      const updatedPlacedBets = [...prevState.placedBets];
      updatedPlacedBets[index] = parseFloat(amount);
      return { placedBets: updatedPlacedBets };
    });
  };

  // Calculate total of placed bets
  calculateTotalPlacedBetAmount = () => {
    return this.state.placedBets.reduce((total, amount) => total + (amount || 0), 0);
  };

  render() {
    let { header, auth, mobile } = this.state
    let { content } = this.props // Pages / Games Contents
    let wallet // Show Wallet if User don't Have Cash

    try {
      wallet = this.props.get_wallet.show
    } catch (e) { }

    const { t } = this.props

    const totalPlacedBetAmount = this.calculateTotalPlacedBetAmount();

    return (
      <>
        {header}
        <div className="page-wrapper">

          <div className="page-content container pb-3" id={'page-content'}>
            <div className='mb-0 p-0 show-mobile h2-play'>
              <div className='row p-0'>
                <div className='col-12'>
                  <Menus />
                </div>
                {/* <div className='col-6'>
                        <a href='tel:0101290080' className='float-right mt-2 pr-2' style={{fontSize:'12px'}}><i className='mdi mdi-phone'></i>: 0101 290 080</a>
                        </div> */}
              </div>
            </div>
            <div className='carousel-section show-mobile'>
              <Carousel />
            </div>

            {wallet && <WalletAlert t={t} uid={this.props.get_wallet.uid} />}
            {auth === true && <Login t={t} justModal="true" />}
            <div className="content-section">
              <div className="row m-0">

                <div className="col-md-8 px-1">

                  <div className="row">
                    <div className="col-md-12 px-2" >
                      <div className="mt-1 p-0">
                        <Canvas mobile={this.state.mobile} totalPlacedBetAmount={totalPlacedBetAmount} />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-12 pl-0 pr-0">
                      <div className='d-bets ml-2 mr-1 my-2 pr-0' style={{ marginTop: '2px' }}>
                        <Bet
                          mobile={this.state.mobile}
                          token={this.state.token}
                          isLogged={this.state.isLogged}
                          bid='0'
                        />
                      </div>
                    </div>
                  </div> */}


                  <div className="row d-bets my-2" style={{ margin: '0 1px' }}>
                    <div className="col-md-6 col-12 pl-0 pr-0">
                      <div className='ml-2 mr-1 my-1 pr-0' style={{ marginTop: '2px' }}>
                        <ManualBet1
                          mobile={this.state.mobile}
                          token={this.state.token}
                          isLogged={this.state.isLogged}
                          bid='0'
                          color='GREY'
                          odd='2.00'
                          inplay={false}
                          updateBetAmount={(amount) => this.updateBetAmount(0, amount)} // Pass the function
                          onPlaceBet={(amount) => this.handlePlaceBet(0, amount)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12 pl-0 pr-0">
                      <div className=' ml-2 mr-1 my-1 pr-0' style={{ marginTop: '2px' }}>
                        <ManualBet2
                          mobile={this.state.mobile}
                          token={this.state.token}
                          isLogged={this.state.isLogged}
                          bid='1'
                          color='PURPLE'
                          odd='3.00'
                          inplay={false}
                          updateBetAmount={(amount) => this.updateBetAmount(1, amount)} // Pass the function
                          onPlaceBet={(amount) => this.handlePlaceBet(1, amount)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12 pl-0 pr-0">
                      <div className=' ml-2 mr-1 my-1 pr-0' style={{ marginTop: '2px' }}>
                        <ManualBet3
                          mobile={this.state.mobile}
                          token={this.state.token}
                          isLogged={this.state.isLogged}
                          bid='2'
                          color='ORANGE'
                          odd='6.00'
                          inplay={false}
                          updateBetAmount={(amount) => this.updateBetAmount(2, amount)} // Pass the function
                          onPlaceBet={(amount) => this.handlePlaceBet(2, amount)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12 pl-0 pr-0">
                      <div className='ml-2 mr-1 my-1 pr-0' style={{ marginTop: '2px' }}>
                        <ManualBet4
                          mobile={this.state.mobile}
                          token={this.state.token}
                          isLogged={this.state.isLogged}
                          bid='3'
                          color='GREEN'
                          odd='99.00'
                          inplay={false}
                          updateBetAmount={(amount) => this.updateBetAmount(3, amount)} // Pass the function
                          onPlaceBet={(amount) => this.handlePlaceBet(3, amount)}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-4 p-1">
                  <div className="clear-bg ">
                    <div className="m-0 p-0">
                      <Queue2 t={this.props.t} />
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="history_ hide-mobile">
                <HistoryList game={'crash'} t={this.props.t} />
              </div> */}
            </div>

            <div className='container bottom-section-mobile-2 mt-2'>
              <div className=" row">
                <div className="container-fluid">
                  <div className='row m-0 p-0'>
                    {/* <div className='col-md-1 m-0 p-0 '>
                        <img src="/assets/images/avatar.svg" style={{width:'100%',marginTop:'40px'}} alt="profile-user"/>
                        <p className='text-center'>Profile</p>
                    </div> */}
                    <div className='col-md-12 m-0 p-0 '>
                      {/* <Queue2 t={this.props.t} /> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className='footer-section pb-2'>
              <div className="row text-lightt">
                <div className="col-md-3" style={{ marginBottom: '4px' }}>
                  <div className="footer-card  text-left foot-line">
                    {/* <h4>Lipa na Mpesa</h4>
                  <div>Paybill Number: 0000420</div> */}
                    <h3>Support</h3>

                    <div>
                      <a href="https://wa.me/0727499399" target="_blank">
                        <i className={'mdi mdi-whatsapp'} style={{ color: '#34B7F1' }}></i>
                        {/* <img
                          src="/assets/images/icons/kes.svg"
                          className="btn-icon"
                        ></img>{' '} */}
                        0727 499 399
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-3" style={{ marginBottom: '4px' }}>
                  <div className="footer-card text-left foot-line">
                    <h3>Licence</h3>
                    <ul>
                      <li>BCLB No: 0000100</li>
                      <li><a href="#"></a></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3" style={{ marginBottom: '4px' }}>
                  <div className="footer-card text-left foot-line">
                    <h3>Quick Links</h3>
                    <ul>
                      <li> <Menus /></li>
                      <li><a href="#"> Terms & Conditions</a></li>
                      <li><a href="#">{/* Privacy Policy */}<PrivacyPolicy t={t} /></a></li>
                      {/* <li>18+ Responsible Betting</li> */}
                      <li></li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-3" style={{ marginBottom: '4px' }}>
                  <div className="footer-card text-left foot-line">
                    <h3>Social Media</h3>
                    {/* <ul className='list-group'>
                      <li className='text-centre'>
                        <i class="mdi mdi-facebook fb"></i> Facebook
                      </li>
                      <li className='text-centre'>
                        <i class="mdi mdi-twitter twta"></i> Twitter
                      </li>
                      <li className='text-centre'>
                        <i class="mdi mdi-instagram insta"></i> Instagram
                      </li>
                    </ul> */}
                    <ul className='socials nav'>
                      <li className='nav-item'><a href="#"><i class="mdi mdi-facebook fb"></i> Facebook</a></li>
                      <li className='nav-item'><a href="#"><i class="mdi mdi-twitter twta"></i> Twitter</a></li>
                      <li className='nav-item'><a href="#"><i class="mdi mdi-instagram insta"></i> Instagram</a></li>
                      <li className='nav-item'><a href="#"><i class="mdi mdi-instagram insta"></i> Tiktok</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row text-lightt">
                <div className='container-fluid'>
                  <p className='text-centre'>&copy; 2023 Betpoa | All Rights Reserved</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}

Index.propTypes = {
  get_wallet: PropTypes.string,
}

const mapStateToProps = (state) => ({
  get_wallet: state.items.get_wallet,
})

export default connect(mapStateToProps, null)(Index)
