import React from "react";
import GameModal from "../../../Components/Game/Stat/Modal";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import Engine from "../Engine";
import { __, isMobile, pickOddColor } from "../../../../Helper";

class SpinGameColors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            name: storage.getKey('name'),
            token: storage.getKey('token'),
            game: this.props.game,
            gameRow: [],
            engine: Engine,
            colors : [],
        };
    }

    componentDidMount() {
        let { engine } = this.state;
        engine.trigger.on("busted_crash", data => this.busted(data));
        engine.trigger.on("game_status", data => this.gameSync(data));

        this.state.colors = [
            { name: 'gray', win: 'x2' },
            { name: 'purple', win: 'x3' },
            { name: 'orange', win: 'x6' },
            { name: 'green', win: 'x99' }
        ];
    }

    busted(data) {
        let busted = (data.amount / 100).toFixed(2);
        //let color = (busted >= 1.5 ? 'bet-success' : 'bet-danger');
        let color = pickOddColor(busted);

        if (isMobile()) {
            if (this.state.gameRow.length === 4) {
                this.state.gameRow.shift();
            }
        }
        else {
            if (this.state.gameRow.length === 4) {
                this.state.gameRow.shift();
            }
        }

        let add = this.state.gameRow.concat([
            <li className={color} key={data.game_id} >
                <GameModal color={color} title={busted} game_id={data.game_id} font={13} />
            </li>
        ]);

        this.setState({ gameRow: add });
    }

    gameSync(list) {
        // console.log(list.crashes);
        if (list.crashes === undefined) return;
        if (list.crashes.length === 0) return;

        // list.crashes.forEach((array, i) => {
        //     if (i < 10) {
        //         let busted = (array.amount / 100).toFixed(2);
        //         //let color = (busted >= 1.5 ? 'bet-success' : 'bet-danger');
        //         let color = pickOddColor(busted);
        //         let row = <li className={color} key={i}>
        //             <GameModal key={i} color={color} title={busted} game_id={array.game_id} font={13} />
        //         </li>;
        //         this.setState(state => ({ gameRow: [row, ...state.gameRow] }));
        //     }
        // });

        this.state.colors.forEach((array, i) => {
            if (i < 5) {
                let busted = (array.amount / 100).toFixed(2);
                //let color = (busted >= 1.5 ? 'bet-success' : 'bet-danger');
                let color = pickOddColor(busted);
                let row = <li>
                    {/* <GameModal key={i} color={color} title={busted} game_id={array.game_id} font={13} /> */}
                    {/* <div className='circle-pick' style={{backgroundColor: `${array.color}`}}></div> */}

                </li>;
                this.setState(state => ({ gameRow: [row, ...state.gameRow] }));
            }
        });
    }

    render() {
        if (isMobile()) {
            if (this.state.gameRow.length >= 5)
                this.state.gameRow.length = 5;
        }
        else {
            if (this.state.gameRow.length >= 10)
                this.state.gameRow.length = 10;
        }
        return (
            <div className="nav font-14 font-weight-bold mycolors">
                {/* <ul>
                    {this.state.gameRow}
                </ul> */}
                <ul>
                {this.state.colors.map((color, index) => (
                    <li key={index}>
                        {/* <span style={{ backgroundColor: color.code, padding: '0 10px', marginRight: '10px' }}>&nbsp;</span>
                        {color.name} */}
                        <div className={`circle-pick ${color.name}`}></div> 
                        <span>{color.win}</span>
                    </li>
                ))}
            </ul>
            </div>

        );
    }
}

export default SpinGameColors;