import React, { Component } from 'react'
import Modal from "react-bootstrap/Modal";

class Menus extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        this.checkSession();
    }

    handleClose(){
        this.setState({ show: false, effect: 'zoomOut' });
    }

    handleShow(){
        this.setState({ show: true, effect: 'zoomIn' });
    }

    setCookie(c_name,value,exdays){
        var exdate=new Date();exdate.setDate(exdate.getDate() + exdays);var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());document.cookie=c_name + "=" + c_value;
    }

    getCookie(c_name){
        var c_value = document.cookie;var c_start = c_value.indexOf(" " + c_name + "=");if (c_start == -1){c_start = c_value.indexOf(c_name + "=");}if (c_start == -1){c_value = null;}else{c_start = c_value.indexOf("=", c_start) + 1;var c_end = c_value.indexOf(";", c_start);if (c_end == -1){c_end = c_value.length;}c_value = unescape(c_value.substring(c_start,c_end));}return c_value;
    }

    checkSession(){
        var c = this.getCookie("visited");
        if (c === "yes") {
        //   alert("Welcome back!");
          this.handleClose();
        } else {
          this.setCookie("visited", "yes", 365);
        //   alert("Welcome new visitor!");
        this.handleShow();
        }
     }
   

    render() {
        return(
            <>
                <span onClick={this.handleShow} className={"btn btn-sm bg-cs2 cpt"} style={{color:'#fff',padding:'0'}}>
                    <i className={'mdi mdi-information mr-1'} />
                    How To Play
                </span>
                <Modal
                    size="md"
					backdrop="static"
                    centered={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="help-md-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className="Header">
                        How To Play
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-helper">
                        {/* <h4 className="text-white">
                            What Is Kapamia ?
                        </h4>
                        <p className={'font-light text-b2'}>
                            Kapamia is the best online pesa multiplayer crash game that enables players play with their own cashout points as the curve increases and may crash at any time.
                        </p>
                        <h4 className="text-white">
                            How To Play ?
                        </h4> */}
                        <p className={'font-light text-b2'}>
                            <i class="mdi mdi-arrow-right mr-1"></i> For each round, enter your stake adjacent to the color you feel will win and click Bet then wait for the wheel to spin.
                        </p>
                        <p className={'font-light text-b2'}>
                            <i class="mdi mdi-arrow-right mr-1"></i>You can stake on as many colors as you wish for each round.
                        </p>

                        <p className={'font-light text-b2'}>
                        <i class="mdi mdi-arrow-right mr-1"></i>  Once the wheel stops, the color where the pointer points wins that round and your stake is multiplied by that winning color's odd.
                        </p>

                        <h4 className="text-white">
                            Color Odds
                        </h4>
                        <p className={'font-light text-b2'}>
                        
                            <code style={{color: '#D2DEF8'}}>GREY - X2</code> <span></span>
                            <br/>
                            <code style={{color: '#85F'}}>PURPLE - X3</code> <span></span>
                            <br/>
                            <code style={{color: '#FF7E2E'}}>ORANGE - X6</code> <span></span>
                            <br/>
                            <code  style={{color: '#15FF73'}}>GREEN - X99</code> <span></span>
                        </p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Menus;