import { CRASH_WINNER_TEXT, CRASH_IM_IN_GAME,CRASH_IM_IN_GAME_1,CRASH_IM_IN_GAME_2} from './types';

export const setWinnerText = profit => dispatch => {
    dispatch({
        type: CRASH_WINNER_TEXT,
        payload: profit
    })
};

export const setMeToGame = enter => dispatch => {
    dispatch({
        type: CRASH_IM_IN_GAME,
        payload: enter
    })
};

export const setMeToGame1 = enter => dispatch => {
    dispatch({
        type: CRASH_IM_IN_GAME_1,
        payload: enter
    })
};

export const setMeToGame2 = enter => dispatch => {
    dispatch({
        type: CRASH_IM_IN_GAME_2,
        payload: enter
    })
};